// @flow
import * as React from 'react'
import { CTAPrimary } from './shared/buttons'
import { openSubscribe } from '../actions/navigationActions'
import type { AppStateType } from '../reducers/appstate'
import type { Dispatch } from 'redux'
import { withRouter } from 'next/router'
import { connect, useSelector } from 'react-redux'

type PropsType = {
  ctaText?: string,
  styles?: string,
  id?: string
}

type DispatchPropsType = {
  openSubscribe: () => any
}

function SubscribeCta (props: PropsType & DispatchPropsType): React.Node {
  const subscriptionCta = useSelector<string>((state: AppStateType): string => state.subscriptionCTA)
  return (
    <CTAPrimary
      onClick={(): void => props.openSubscribe()}
      minWidth={232}
      styles={props.styles}
      id={props.id}
      className='subscribe-cta'
    >
      {props.ctaText || subscriptionCta}
    </CTAPrimary>
  )
}

const mapStateToProps = (state: AppStateType): any => {
  return {
    isSubscribed: state.user.isSubscribed,
    signedIn: state.user.id
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): any => {
  return ({
    openSubscribe: (): void => dispatch(openSubscribe())
  }: DispatchPropsType)
}

export default withRouter(connect<PropsType & DispatchPropsType, *, _, _, _, _>(mapStateToProps, mapDispatchToProps)(SubscribeCta))

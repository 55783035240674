// @flow
import * as React from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import styled from 'styled-components'

type PropsType = {
  uniqueId: string,
  children: React.Element<any>
}
export default function ParallaxHOC (props: PropsType): React.Node {
  return (
    <Controller refreshInterval={50}>
      <Scene
        triggerHook="onEnter"
        offset={200}
        classToggle={'fade-in'}
        reverse={false}
      >
        <HiddenElement>
          {props.children}
        </HiddenElement>
      </Scene>
    </Controller>
  )
}

const HiddenElement = styled.div`
  will-change: opacity, transform;
  transition: transform .5s ease-in, opacity .5s ease-in;
  transform: translate3d(0,25px,0);
  opacity: 0;
  &.fade-in {
    transform: translate3d(0,0,0);
    opacity: 1;
    width: 100%;
  }
`

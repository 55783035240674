// @flow
import * as React from 'react'
import styled from 'styled-components'
import { config } from 'react-awesome-styled-grid'
import Link from 'next/link'
import type { SeriesInfoPropsType } from '../../../models/Series'
import { getPopularSeriesProps } from '../../../models/Series'
import { FadeInImage } from '@components/shared/FadeInImage'
import { useRouter } from 'next/router'
import { HeaderSeeAll } from '@components/shared/HeaderSeeAll'
import { colors } from '@components/shared'
import TrackTags from '@components/Tag/TrackTags'

type PropsType = {
  title?: string
}

export function FeatureSeries (props: PropsType): React.Node {
  const router = useRouter()
  const [series, setSeries] = React.useState<SeriesInfoPropsType[]>([])
  React.useEffect((): void => {
    getPopularSeriesProps().then((series: SeriesInfoPropsType[]): void => {
      setSeries(series.slice(0, 15))
    })
  }, [])
  if (series.length === 0) return null
  const cta = {
    url: '/series',
    title: 'SEE ALL'
  }
  return (
    <FeatureSeriesContainer>
      <HeaderSeeAll title={props.title || 'Top Audiobooks'} cta={cta}/>
      <ListContainer>
        {series.map((item: SeriesInfoPropsType, index: number): React.Node => (
          <ListItemContainer key={`featured-series-${index}`} onClick={(): void => router.push(item.slug)}>
            <ImageContainer>
              <FadeInImage src={item.images.img500} width={500} height={500} />
            </ImageContainer>
              <HeaderTitleText>
                <Link href={item.slug} passHref={true}>{item.title}</Link>
              </HeaderTitleText>
              <TagText>{item.episodeInfo}</TagText>
              <DescriptionText>{item.description}</DescriptionText>
              <TrackTags tags={
                // $FlowFixMe
                item.thematicTags || []
              } maxLines={2} clickable={true}/>
          </ListItemContainer>))}
      </ListContainer>
    </FeatureSeriesContainer>
  )
}

const HeaderTitleText = styled.div`
    a {
        margin-top: 8px;
        color: ${colors.white};
        font-family: "Good Sans Bold";
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-decoration: none;
        letter-spacing: -0.3px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        ${(props: any): string => config(props).media.md`
          margin-top: 20px;
        `}
        
        ${(props: any): string => config(props).media.lg`
          font-size: 18px;
          letter-spacing: 0px;
        `}
    }
`
const ImageContainer = styled.div`
    width: 162px;
    height: 162px;
    position: relative;
    z-index: 0;
    img {
        border-radius: 8px;
    }

    ${(props: any): string => config(props).media.md`
      width: 182px;
      height: 182px;
    `}
    ${(props: any): string => config(props).media.lg`
      width: 200px;
      height: 200px;
  `}
    ${(props: any): string => config(props).media.xl`
      width: 226px;
      height: 226px;
    `}
`

const ListItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
const ListContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.md`
      gap: 16px;
  `}
    ${(props: any): string => config(props).media.lg`
      gap: 20px;
    `}
    ${(props: any): string => config(props).media.xl`
      gap: 32px;
    `}
`

const FeatureSeriesContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
export const TagText = styled.p`
  color: ${colors.white70};
  font-family: "Good Sans Light";
  font-style: normal;
  font-weight: normal;
  margin: 0;
  font-size: 11px;
    line-height: 150%;
  ${(props: any): string => config(props).media.lg`
    font-size: 16px;
  `}
`

export const DescriptionText = styled.p`
  color: ${colors.white};
  font-family: "Good Sans Light";
  font-style: normal;
  font-weight: normal;
  margin: 8px 0px 8px 0px;
  font-size: 11px;
  line-height: 125%;
  ${(props: any): string => config(props).media.lg`
    font-size: 16px;
    margin: 10px 0px 10px 0px;
  `}
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

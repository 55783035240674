// @flow
import { withRouter } from 'next/router'
import * as React from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import Footer from '@components/Footer'
import TopNav from '@components/TopNav'
import TopNavMobile from '@components/TopNavMobile'
import HeaderSection from '@components/home/HeaderSection'
import NewReleasesAndPopularTracks from '@components/home/NewReleases'
import PressQuotesAndDriveToDownloadSection from '@components/home/PressQuotesAndDriveToDownloadSection'
import SeriesAndCharacters from '@components/home/SeriesAndCharacters'
import Spotlight from '@components/home/Spotlight'
import TagSection from '@components/home/TagSection'
import { GlobalStyle } from '@components/shared'
import { colors } from '@components/shared/colors'
import { openOurStory } from '../actions/navigationActions'
import HtmlHead from '../components/HtmlHead'
import type { AppStateType } from '../reducers/appstate'
import type { HomePageSpotLightType } from '../services/contentfulPosts'
import { fetchHomePageSpotlight } from '../services/contentfulPosts'

type PropsType = {
  spotlightSection: HomePageSpotLightType
}

type DispatchPropsType = {
  openAboutPage: () => void
}

function Home (props: PropsType & DispatchPropsType): React.Node {
  const [isAssetLoaded, setIsAssetLoaded] = React.useState < boolean >(false)
  return (
    <>
      <GlobalStyle backgroundColor={colors.midnightBlue} />
      <HtmlHead title={'Dipsea | Spicy Audiobooks'}
        description={'Spicy audiobooks and more, featuring your favorite tropes and irresistible characters. Crafted by our team of professional writers and top-tier narrators.'} />
      <TopNav backgroundColor={colors.midnightBlue95} isAssetLoaded={true} />
      <TopNavMobile backgroundColor={colors.midnightBlue95} isAssetLoaded={true} />
      <main>
        <HeaderSection imageLoadingComplete={(): void => setIsAssetLoaded(true)} carouselLength={6}/>
        {isAssetLoaded && <NewReleasesAndPopularTracks />}
        {isAssetLoaded && <SeriesAndCharacters />}
        {isAssetLoaded && <TagSection />}
        {isAssetLoaded && <Spotlight spotlightInfo={props.spotlightSection} />}
        {isAssetLoaded && <PressQuotesAndDriveToDownloadSection />}
      </main>
      {(isAssetLoaded) && <Footer backgroundColor={colors.midnightBlue} />}
    </>
  )
}

export async function getStaticProps (): Promise<{ props: PropsType }> {
  const spotlightSection = await fetchHomePageSpotlight()

  return {
    props: {
      spotlightSection
    }
  }
}

const mapStateToProps = (state: AppStateType, ownProps: PropsType): any => {
  return ownProps
}

const mapDispatchToProps = (dispatch: Dispatch<any>): any => {
  return {
    openAboutPage: (): void => dispatch(openOurStory())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))

// @flow
import * as React from 'react'
import { Col, config, Container, Row } from 'react-awesome-styled-grid'
import { NewReleasesRow } from '@components/home/NewReleases/NewReleasesRow'
import { PopularTracksRow } from '@components/home/NewReleases/PopularTracksRow'
import { CTASecondaryLink, colors } from '@components/shared'
import styled from 'styled-components'
import FadeInContent from '@components/shared/FadeInContent'
import { useSelector } from 'react-redux'
import type { AppStateType } from '@reducers/appstate'

export default function NewReleasesAndPopularTracks (props: {}): React.Node {
  const ctaText = useSelector<string>((state: AppStateType): string => state.subscriptionCTA)
  return (
    <FadeInContent fadeInTime={1}>
      <Container>
        <Row>
          <Col>
            <Wrapper>
              <NewReleasesRow />
              <PopularTracksRow />
              <UnlockSection>
                <HeaderTextContainer>Listen to 1,000+ spicy audiobooks and more.</HeaderTextContainer>
                <CTASecondaryLink className='subscribe-cta' passHref={true} href={'/subscribe'} color={colors.sand}>{ctaText}</CTASecondaryLink>
              </UnlockSection>
            </Wrapper>
          </Col>
        </Row>
      </Container>
    </FadeInContent>
  )
}

const HeaderTextContainer = styled.div`
    text-align: center;
    font-family: "Queens Condensed Light";
    color: ${colors.sand};
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    margin-bottom: 24px;
    
    ${(props: any): string => config(props).media.sm`
      margin-bottom: 28px;
    `}
    
    ${(props: any): string => config(props).media.md`
      font-size: 50px;
      margin-bottom: 30px;
    `}
    
    ${(props: any): string => config(props).media.lg`
      font-size: 60px;
    `}
`

const UnlockSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 0px 80px 0px;
    width: 100%;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
    padding: 160px 0px 160px 0px;
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
const Wrapper = styled.div`
  min-height: 50vh;
`

// @flow
import * as React from 'react'
import { config } from 'react-awesome-styled-grid'
import styled from 'styled-components'
import ReviewSlider from '@components/ReviewSlider'
import { colors } from '@components/shared/colors'
import { P } from '@components/shared/typography'
import type { ReviewType } from '@components/ReviewSlider'

function ReviewSection (props: {title: string, slides: ReviewType[]}): React.Node {
  return (
    <>
        <ReviewSectionTitle>
          {props.title}
        </ReviewSectionTitle>
        <ReviewSlider
          slides={props.slides}
        />
    </>
  )
}

const ReviewSectionTitle = styled(P)`
  text-align: center;
  width: 100%;
  max-width: 270px;
  margin: 30px auto 42px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.03em;
  color: ${colors.sand};
  ${(props: any): string => config(props).media.md`
    max-width: unset;
    margin-top: 90px;
  `}
  ${(props: any): string => config(props).media.lg`
    margin-top: 115px;
  `}
`

export default ReviewSection

// @flow
import * as React from 'react'
import { Col, Container, Row, config } from 'react-awesome-styled-grid'
import styled from 'styled-components'
import BackgroundGradient from '@components/BackgroundGradient'
import FadeInHOC from '@components/FadeInHOC'
import { FeatureSeries } from '@components/home/SeriesAndCharacters/FeaturedSeries'
import { MeetOurCharacters } from '@components/home/SeriesAndCharacters/MeetOurCharacters'
import ReviewSection from '@components/ReviewSection'

function SeriesAndCharacters (): React.Node {
  const slides = [
    {
      audioUrl: '',
      key: 'ELLIE',
      quote: `Dipsea is unparalleled when it comes to the production value of their audiobooks and their focus on storytelling, and I love that they have multiple voice actors to play each part!`,
      name: `ELLIE`
    },
    {
      audioUrl: '',
      key: 'ANISHA',
      quote: `Really refreshing take on romance. I love the realism of the stories. Great storytelling, voice acting, and sound design. Well done!`,
      name: `ANISHA`
    },
    {
      audioUrl: '',
      key: 'RACHEL',
      quote: `The narrators are outstanding! The accents are outstanding! The heat factor is volcanic—just off the charts.`,
      name: `RACHEL`
    },
    {
      audioUrl: '',
      key: 'MAYA',
      quote: `This app has so many story genres, whether you’re into fantasy, cowboys, or just sweet and flirty. Definitely a lot of good storytelling by talented writers and voice actors!`,
      name: `MAYA`
    },
    {
      audioUrl: '',
      key: 'JAHNAVI',
      quote: `I love how varied and queer-friendly this app is. The characters feel like real, interesting people and that’s what makes them so compelling.`,
      name: `JAHNAVI`
    },
    {
      audioUrl: '',
      key: 'CAYLEE',
      quote: `Smart, subtle story details that let you know these stories were written by women. Great build-ups and story arcs. Plus the app is well-designed and easy to navigate. Highly recommend!`,
      name: `CAYLEE`
    },
    {
      audioUrl: '',
      key: 'LEAH',
      quote: `I love this app so much. It’s inclusive, with many options for different genders, orientations, scenarios, and interests. Honestly their audiobooks are fun and refreshing and it’s firmly one of the best apps out there for femme audiences.`,
      name: `LEAH`
    }
  ]
  return (
    <Section>
      <BackgroundGradient
        quadrant={3}
        overlayColor='magenta'
        parallax
      />
      <TrackPreviewSection>
        <FadeInHOC uniqueId="track-section">
          <Container fluid={true}>
            <Row>
              <Col>
                <FeatureSeries />
              </Col>
            </Row>
            <Row>
              <Col>
                <MeetOurCharacters />
              </Col>
            </Row>
            <Row>
              <ReviewSection title={'You don’t have to take our word for it'} slides={slides}/>
            </Row>
          </Container>
        </FadeInHOC>
      </TrackPreviewSection>
    </Section>
  )
}

const Section = styled.section`
  position: relative;
`
const TrackPreviewSection = styled.div`
  overflow: hidden;
    padding: 60px 0;
    ${(props: any): string => config(props).media.md`
    padding: 70px 0;
  `}
  ${(props: any): string => config(props).media.lg`
    padding: 80px 0;
  `}
`

export default SeriesAndCharacters

// @flow
import * as React from 'react'
import { Col, Container, Row, Visible, config } from 'react-awesome-styled-grid'
import styled from 'styled-components'
import BackgroundGradient from '@components/BackgroundGradient'
import SubscribeCta from '@components/SubscribeCta'
import { Carousel } from '@components/home/Carousel'
import FadeInContent from '@components/shared/FadeInContent'
import { colors } from '@components/shared/colors'
import { H1, P } from '@components/shared/typography'
import { useSelector } from 'react-redux'
import type { AppStateType } from '@reducers/appstate'

type PropsType = {
  imageLoadingComplete: () => void,
  carouselLength: number
}

export default function HeaderSection (props: PropsType): React.Node {
  const headerText = `Romance, reimagined.`
  const descriptionText = `Spicy audiobooks and more, featuring irresistible characters and steamy storylines. Our team of professional writers and top-tier narrators build immersive romantic worlds to explore.`
  const ctaText = useSelector<string>((state: AppStateType): string => state.subscriptionCTA)

  return (
    <Section>
      <BackgroundGradient
        loadingComplete={(): void => props.imageLoadingComplete()}
        quadrant={1}
        gradientColor={colors.midnightBlue}
        gradientPosition='bottom'
        parallax
      />
      <ContentContainer>
        <Container>
          <Row >
            <Col
              xs={4}
              sm={8}
              md={4}
              lg={5}
              xl={5}
              offset={{ md: 0, lg: 1, xl: 1 }}
              justify="center"
            >
              <HeaderTextContainer>
                <HeaderText>{headerText}</HeaderText>
                <Description>{descriptionText}</Description>
                <SubscribeCta ctaText={ctaText} />
              </HeaderTextContainer>
            </Col>
            <Visible xs={false} sm={false} md={true} lg={true} xl={true}>
              <Col xs={4} sm={8} md={4} lg={5} xl={5} align={'center'} justify={'center'}>
                <FadeInContent>
                  <Carousel {...props} />
                </FadeInContent>
              </Col>
            </Visible>
          </Row>
        </Container>
      </ContentContainer>
    </Section>
  )
}

const Section = styled.section`
  align-items: center;
  display: flex;
  margin: -60px 0 0;
  overflow: hidden;
  position: relative;
  ${(props: any): string => config(props).media.md`
    margin: -75px 0 0;
  `}
`
const ContentContainer = styled.div`
  padding: 60px 0 28px;
  width: 100%;
  ${(props: any): string => config(props).media.md`
    padding: 75px 0 28px;
  `}
`
const HeaderTextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 40px;
  min-height: 40vh;
  z-index: 1;
  h1,
  p,
  div {
    text-align: center;
  }

  ${(props: any): string => config(props).media.md`
    align-items: flex-start;
    padding: 80px 0;
    h1,
    p,
    div {
      text-align: left;
    }
  `}
`
const HeaderText = styled(H1)`
  color: ${colors.sand};
  font-family: 'Queens Condensed Light';
  font-size: 60px;
  font-weight: 300;
  letter-spacing: -0.009em;
  line-height: 105%;
  margin: 0 0 0.5em 0;

  ${(props: any): string => config(props).media.lg`
    font-size: 90px;
    margin: 0 0 .2em 0;
  `}
`
const Description = styled(P)`
  color: ${colors.sand};
  font-size: 16px;
  line-height: 150%;
  margin: 0 0 1.5em;
  ${(props: any): string => config(props).media.lg`
    font-size: 18px;
    margin: 0 0 1.5em;
  `}
`

// @flow
import * as React from 'react'
import { FadeInImage } from '@components/shared/FadeInImage'
import PlayIcon from '@components/shared/icons/PlayIcon'
import styled from 'styled-components'
import { config } from 'react-awesome-styled-grid'
import { colors, GoodSansB5 } from '@components/shared'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  convertCharacterCategoryToListPropsAsync
} from '../../../models/Character'
import type {
  CharacterInfoPropsType,
  CharactersListPropsType
} from '../../../models/Character'
import { HeaderSeeAll } from '@components/shared/HeaderSeeAll'

export function MeetOurCharacters (props: {}): React.Node {
  const router = useRouter()
  const [characters, setCharacters] = React.useState<CharacterInfoPropsType[] | null>(null)
  React.useEffect((): void => {
    convertCharacterCategoryToListPropsAsync(true).then((res: CharactersListPropsType): void => {
      setCharacters(res.characters.filter((item: CharacterInfoPropsType): boolean => !!item.imageUrl).slice(0, 12))
    })
  }, [])
  if (!characters) return null
  const cta = {
    title: 'SEE ALL',
    url: '/characters'
  }
  return (
    <MeetOurCharactersContainer>
      <HeaderSeeAll title="Meet our Characters" cta={cta}/>
      <CharacterListContainer>
        <MeetOurCharactersGridList>
          {characters.map((character: CharacterInfoPropsType, index: number): React.Node => {
            return (
              <MeetOurCharactersListItem key={`popular-${index}`} onClick={(): void => router.push(character.slug)}>
                <CharacterInfoAndImageContainer>
                  <ImageContainer><FadeInImage src={character.imageUrl} height={500} width={500}/></ImageContainer>
                <ItemInfoContainer>
                  <ItemTitle><Link href={character.slug} passHref={true}>{character.title}</Link></ItemTitle>
                  <ItemDescription>{character.description}</ItemDescription>
                </ItemInfoContainer>
                </CharacterInfoAndImageContainer>
                <IconContainer>
                  <PlayIcon size={24}/>
                </IconContainer>
              </MeetOurCharactersListItem>
            )
          })}
        </MeetOurCharactersGridList>
      </CharacterListContainer>
    </MeetOurCharactersContainer>
  )
}

const ItemInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    width: 200px;

    ${(props: any): string => config(props).media.lg`
      width: 240px;
  `}
    ${(props: any): string => config(props).media.xl`
      width: 280px;
  `}
`

const ImageContainer = styled.div`
    height: 50px;
    width: 50px;
    img {
        border-radius: 8px;
    }
    ${(props: any): string => config(props).media.md`
      height: 67px;
      width: 67px;
    `}
`

const ItemTitle = styled.div`
    ${GoodSansB5};
    a {
        color: ${colors.white};
        font-family: "Good Sans Bold";
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-decoration: none;
        letter-spacing: -0.3px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        ${(props: any): string => config(props).media.lg`
          font-size: 18px;
          letter-spacing: 0px;
        `}
    }
`

const ItemDescription = styled.div`
    font-family: "Good Sans Regular";
    color: ${colors.white70};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.3px;
    
    ${(props: any): string => config(props).media.md`
      font-size: 14px;
    `}
    
`

const MeetOurCharactersListItem = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 0px;
    width: 312px;
    border-bottom: 0.5px solid ${colors.white25};

    ${(props: any): string => config(props).media.md`
      width: 100%;
    `}
`
const MeetOurCharactersGridList = styled.ul`
    display: grid;
    margin: 0;
    padding: 0;
    width: 312px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0px 16px;
    ${(props: any): string => config(props).media.md`
      grid-template-columns: auto auto auto;
      width: 100%;
    `}
    ${(props: any): string => config(props).media.lg`
      grid-gap: 0px 40px;
    `}
`

const CharacterListContainer = styled.div`
    width: 100%;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
`

const MeetOurCharactersContainer = styled.div`
    width: 100%;
    margin-top: 25px;
    ${(props: any): string => config(props).media.xs`
      margin-top: 50px;
    `}

    ${(props: any): string => config(props).media.md`
      margin-top: 60px;
    `}
`

const CharacterInfoAndImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 2;
`

const IconContainer = styled.div`
    justify-content: flex-end;
    flex: 1;
    display: flex;
    ${(props: any): string => config(props).media.lg`
      width: 32px;
  `}
`

// @flow
import { PlayPreviewOnlyButton } from '@components/Track/PlayButton'
import { colors } from '@components/shared'
import { OutlineOnFocusVisible } from '@components/shared/buttons'
import { GoodSansB4, GoodSansH1, GoodSansH3, GoodSansB5 } from '@components/shared/typography'
import { isChromeForIOs145 } from '@helpers/utils'
import { useRouter } from 'next/router'
import * as React from 'react'
import { config } from 'react-awesome-styled-grid'
import styled from 'styled-components'
import {
  A11y,
  Autoplay,
  EffectFade,
  Lazy,
  Navigation,
  Pagination,
  EffectCoverflow,
  Mousewheel
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { TrackPropsType, CarouselType } from '../../models/Track'
import { getCarouselTrackProps } from '../../models/Track'
import { v4 as uuidv4 } from 'uuid'

function Slide ({
  track,
  slug,
  title,
  description,
  popularity,
  imageUrl,
  isLazy,
  isCoverflow,
  isSmall
}: {
  track: TrackPropsType,
  slug: string,
  title: string,
  description: string,
  popularity: number,
  imageUrl: string,
  isLazy?: boolean,
  isCoverflow?: boolean,
  isSmall?: boolean
}): React.Node {
  const router = useRouter()
  const click = React.useCallback((): void => {
    router.push(slug)
  }, [slug])

  const handleKeyDown = (event: SyntheticKeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      click()
    }
  }

  return (
    <OutlineWrapper>
      <Container
        onClick={click}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        className={isLazy ? 'swiper-lazy' : ''}
        alt={`Open ${title}`}
      >
        <img src={imageUrl} alt={title} />
        <Gradient />
        <Content isCoverflow={isCoverflow}>
          <div>
            <Header isSmall={isSmall}>{title}</Header>
            <CopyContainer>
              <Copy isSmall={isSmall}>
                {description}
              </Copy>
              {popularity > 0
                ? <TotalPlays isSmall={isSmall}>{`${popularity.toLocaleString()} plays`}</TotalPlays>
                : ''}
            </CopyContainer>
          </div>
          <PreviewContainer>
            <PlayPreviewOnlyButton {...track} size={isSmall ? 28 : 32} />
            <Preview isSmall={isSmall}>PREVIEW</Preview>
          </PreviewContainer>
        </Content>
      </Container>
    </OutlineWrapper>
  )
}
type PropsType = {
  imageLoadingComplete?: () => void,
  tracks: CarouselType[],
  isCoverflow?: boolean,
  isSmall?: boolean
}

export function HomeCarouselComponent (props: PropsType): React.Node {
  const [swiper, setSwiper] = React.useState(null)
  const uniqueId = uuidv4()

  React.useEffect((): void => {
    if (swiper && props.tracks.length > 0) {
      swiper.slideToLoop(0, 0)
      if (props.imageLoadingComplete) props.imageLoadingComplete()
    }
  }, [swiper, props.tracks])

  const tracksLength = props.tracks ? props.tracks.length : 0
  if (props.isCoverflow && tracksLength > 2) {
    return (
      <CoverFlow isSmall={!!props.isSmall}>
        <Swiper
          id={uniqueId}
          onSwiper={setSwiper}
          loop={tracksLength > 3}
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 250,
            modifier: 1,
            slideShadows: true
          }}
          initialSlide={0}
          modules={[EffectCoverflow, A11y, Lazy, Mousewheel]}
          direction="horizontal"
          mousewheel={{ forceToAxis: true, sensitivity: 0.15 }}
          freeMode={false}
        >
          {props.tracks.map(
            (item: CarouselType, index: number): React.Node => (
              <SwiperSlide key={`home-carousel-preview-${item.id}`}>
                <Slide {...item} isCoverflow isSmall={!!props.isSmall} />
              </SwiperSlide>
            )
          )}
        </Swiper>
      </CoverFlow>
    )
  }

  return (
    <HomeCarouselContainer>
      <Swiper
        modules={[Navigation, Pagination, A11y, EffectFade, Autoplay, Lazy, Mousewheel]}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{
          clickable: true,
          type: 'bullets'
        }}
        effect='fade'
        fadeEffect={{
          crossFade: true
        }}
        cssMode={isChromeForIOs145()}
        autoplay={{ delay: 4500 }}
        preloadImages={false}
        lazy={true}
        direction="horizontal"
        mousewheel={{ forceToAxis: true, sensitivity: 0.5 }}
        freeMode={false}
      >
        {props.tracks.map(
          (item: CarouselType, index: number): React.Node => (
            <SwiperSlide key={`home-carousel-preview-${item.id}`}>
              <Slide {...item} isLazy={index > 0} />
            </SwiperSlide>
          )
        )}
      </Swiper>
    </HomeCarouselContainer>
  )
}

type CarouselPropsType = {
  imageLoadingComplete?: () => void,
  isCoverflow?: boolean,
  carouselLength?: number,
  thematicTag?: string[],
  isSmall?: boolean
}

export function Carousel (props: CarouselPropsType): React.Node {
  const [popularTracks, setPopularTracks] = React.useState < CarouselType[] >([])
  const router = useRouter()
  const themeTag = props?.thematicTag?.[0]

  React.useEffect((): void => {
    let path = router.asPath
    let slug = router.query.slug
    if (themeTag) {
      path = 'theme'
      slug = themeTag
    }
    getCarouselTrackProps(path, slug, props.carouselLength).then((res: CarouselType[]): void => {
      if (res.length > 0) {
        setPopularTracks(res)
      }
    })
  }, [router.asPath])
  return <HomeCarouselComponent {...props} isCoverflow={!!props.isCoverflow} tracks={popularTracks} />
}

const CoverFlow = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  height: 30vw;
  min-height: 270px;
  max-height: 400px;

  ${(props: { isSmall: boolean }): ?string => {
    if (props.isSmall) {
      return `
        min-height: unset;
        height: unset;
      `
    }
  }}

  .swiper {
    width: 100%;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 30vw;
    height: 30vw;
    min-width: 270px;
    min-height: 270px;
    max-width: 400px;
    max-height: 400px;
    border-radius: 30px;
    overflow: hidden;

    ${(props: { isSmall: boolean }): ?string => {
    if (props.isSmall) {
      return `
        width: 23vw;
        height: 23vw;
        min-width: 230px;
        min-height: 230px;
        max-width: 360px;
        max-height: 360px;
        border-radius: 20px;
      `
    }
  }}
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }
`
const Header = styled.p`
  ${(props: any): string => props.isSmall ? GoodSansH3 : GoodSansH1};
`
const CopyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Copy = styled.p`
  ${(props: any): string => props.isSmall ? GoodSansB5 : GoodSansB4};
  margin-right: 1em;
  display: inline;
  margin-top: 3px;
`
const TotalPlays = styled.p`
  ${(props: any): string => props.isSmall ? GoodSansB5 : GoodSansB4};
  margin-top: 3px;
  position: relative;
  :before {
    content: "|";
    position: absolute;
    left: -.7em; 
  }
`
const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
`
const Content = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  position: absolute;
  overflow: hidden;
  bottom: 20px;
  left: 18px;
  right: 18px;
  ${(props: any): string => config(props).media.md`
    left: ${(props: any): string => props.isCoverflow ? '20px' : '28px'};
    right: ${(props: any): string => props.isCoverflow ? '20px' : '28px'};
    bottom: ${(props: any): string => props.isCoverflow ? '20px' : '30px'};
  `}
`
const OutlineWrapper = styled.div`
  padding: 2px; /* space for the accessible outline when the Container has keyboard focus */
`
const Container = styled.div`
  background-color: ${colors.midnightBlueTint};
  border-radius: 30px;
  cursor: pointer;
  margin: 0 0 50px;
  padding-top: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
 ${OutlineOnFocusVisible};
  img {
    bottom: 0;
    border-radius: 30px;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`
const HomeCarouselContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 88px 0 22px;
  max-width: 537px;
  width: 100%;
  ${(props: any): string => config(props).media.lg`
    max-width: 700px;
  `}
  .swiper-pagination {
    bottom: 0 !important;
  }
  .swiper-pagination-bullet-active {
    background-color: ${colors.sand};
  }
  .swiper-pagination-bullet {
    border: 1px solid ${colors.sand} !important;
    height: 11px;
    margin: 0 6px !important;
    opacity: 1 !important;
    width: 11px;
    ${OutlineOnFocusVisible};
  }
`
const PreviewContainer = styled.div`
  margin: 0 0 0 12px;
  padding-top: 2px; /* needed for accessible focus */
`
const Preview = styled.p`
  ${GoodSansH3};
  color: ${colors.white};
  margin-top: .25rem;
  font-size: ${(props: any): string => props.isSmall ? '7px' : '8px'};
  ${(props: any): string => config(props).media.md`
    font-size: ${(props: any): string => props.isSmall ? '8px' : '9px'};
  `}
  ${(props: any): string => config(props).media.lg`
    font-size: ${(props: any): string => props.isSmall ? '9px' : '10px'};
  `}
`

// @flow
import { chunk } from 'lodash'
import Link from 'next/link'
import * as React from 'react'
import { Col, config, Container, Row } from 'react-awesome-styled-grid'
import styled from 'styled-components'
import BackgroundGradient from '@components/BackgroundGradient'
import FadeInHOC from '@components/FadeInHOC'
import { CTASecondaryLink } from '@components/shared'
import { colors } from '@components/shared/colors'
import { P } from '@components/shared/typography'
import type { ThematicTagInfoType } from '../../models/ThematicLandingPage'
import { getAllThematicTags } from '../../models/ThematicLandingPage'

function TagSection (props: {}): React.Node {
  const [tags, setTags] = React.useState < ThematicTagInfoType[][] >([])
  React.useEffect((): void => {
    getAllThematicTags().then((tags: ThematicTagInfoType[]): void => {
      const chunks = chunk(tags.slice(0, 80), 18)
      setTags(chunks)
    })
  }, [])
  return (
    <Section>
      <BackgroundGradient
        quadrant={3}
        overlayColor='orange'
        parallax
      />
      <TagSectionDiv>
        <FadeInHOC uniqueId="track-section">
          <Container fluid={true}>
            <Row>
              <ReviewSectionTitle>
                SOMETHING FOR EVERYONE
              </ReviewSectionTitle>
            </Row>
            <Row>
              <Col>
                <TagLine>
                  {tags.map((tagArr: ThematicTagInfoType[], index1: number): React.Node => {
                    return <TagListContainer key={`tags-row-${index1}`} className={`tagrow${index1}`}>
                      {tagArr.map((tag: ThematicTagInfoType, index2: number): React.Node => {
                        return (
                          <TagItem className={'comma'} key={`tag-row-${index1}-${index2}`}>
                            <Link href={tag.slug} passHref={true}>
                              {tag.title}
                            </Link>
                            <span>•</span>
                          </TagItem>
                        )
                      })}
                      {tagArr.map((tag: ThematicTagInfoType, index2: number): React.Node => {
                        return (
                          <TagItem className={'comma'} key={`tag-row-${index1}-${index2}`}>
                            <Link href={tag.slug} passHref={true}>
                              {tag.title}
                            </Link>
                            <span>•</span>
                          </TagItem>
                        )
                      })}
                    </TagListContainer>
                  })}
                </TagLine>
                <ExploreContainerSection>
                  <CTASecondaryLink passHref={true} href={'/search'} color={colors.white}>EXPLORE TAGS</CTASecondaryLink>
                </ExploreContainerSection>
              </Col>
            </Row>
          </Container>
        </FadeInHOC>
      </TagSectionDiv>
    </Section>
  )
}

const Section = styled.section`
  position: relative;
  overflow: hidden;
`
const TagLine = styled.div`
  position: relative;
  width: 100%
`
const TagListContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;

  &&.tagrow0 {
    animation: move-left-to-right 20s linear infinite;
    &:hover {
      animation-play-state: paused;
    }
  }

  &&.tagrow1 {
    animation: move-right-to-left 20s linear infinite;
    &:hover {
      animation-play-state: paused;
    }
  }

  &&.tagrow2 {
    animation: move-left-to-right 20s linear infinite;
    &:hover {
      animation-play-state: paused;
    }
  }

  &&.tagrow3 {
    animation: move-right-to-left 20s linear infinite;
    &:hover {
      animation-play-state: paused;
    }
  }
  
  @keyframes move-left-to-right {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  /* use this one to move from right to left direction */
  @keyframes move-right-to-left {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  a {
    color: ${colors.white};
    text-decoration-thickness: 2px;
    text-underline-offset: 8px;
  }

  ${(props: any): string => config(props).media.md`
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 8px;
      }
    }
      
    &&.tagrow3 {
      display: none;
    }
    margin-bottom: 14px;
  `}
`
const TagItem = styled.div`
  color: ${colors.white};
  font-family: "Queens Condensed Light";
  white-space: nowrap;
  font-size: 52px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  display: flex;

  span {
    margin: 0 16px;
    font-size: 26px;
  }
  ${(props: any): string => config(props).media.sm`
    font-size: 62px;
  `}
  ${(props: any): string => config(props).media.lg`
    font-size: 80px;
  `}
  ${(props: any): string => config(props).media.xl`
    font-size: 90px;
  `}
`

const TagSectionDiv = styled.div`
  padding: 90px 0;
  ${(props: any): string => config(props).media.md`
    padding: 116px 0;
  `}
  ${(props: any): string => config(props).media.lg`
    padding: 115px 0;
  `}
`

const ReviewSectionTitle = styled(P)`
  width: 100%;
  text-align: center;
  font-family: "Good Sans Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.42px;
  text-transform: uppercase;
  margin-bottom: 38px;
  ${(props: any): string => config(props).media.md`
    margin-bottom: 32px;
  `}
  ${(props: any): string => config(props).media.lg`
    margin-bottom: 42px;
  `}
`

const ExploreContainerSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 56px;
  ${(props: any): string => config(props).media.sm`
    margin-top: 39px;
  `}
  ${(props: any): string => config(props).media.md`
    margin-top: 48px;
  `}
  ${(props: any): string => config(props).media.lg`
    margin-top: 56px;
  `}
`
export default TagSection

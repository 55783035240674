// @flow
import * as React from 'react'
import styled from 'styled-components'
import BackgroundGradient from '@components/BackgroundGradient'
import DriveToDownload from '@components/DriveToDownload'
import MediaSlider from '@components/home/PressMediaSlider'

export default function PressQuotesAndDriveToDownloadSection (): React.Node {
  return (
    <Section>
      <BackgroundGradient
        quadrant={3}
        gradientPosition='top'
        parallax
      />
      <MediaSlider />
      <DriveToDownload />
    </Section>
  )
}

const Section = styled.section`
  position: relative;
`

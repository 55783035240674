// @flow
import * as React from 'react'
import styled from 'styled-components'
import { colors } from '@components/shared'
import type { HorizontalMediumTrackRowPropsType } from '../../../models/Discover'
import { getNewReleasesTracks } from '../../../models/Track'
import HorizontalMediumTrackRow from '@components/Discover/HorizontalMediumTrackRow'

export function NewReleasesRow (props: {}): React.Node {
  const [rec, setRec] = React.useState < HorizontalMediumTrackRowPropsType | null >(null)
  React.useEffect((): void => {
    getNewReleasesTracks().then((res: HorizontalMediumTrackRowPropsType): void => {
      setRec(res)
    })
  }, [])
  if (!rec) return null
  return (
    <NewReleasesContainer>
      {rec && <HorizontalMediumTrackRow {...rec} />}
    </NewReleasesContainer>
  )
}

const NewReleasesContainer = styled.div`
  display: flex;
  background: ${colors.midnightBlue};
`
